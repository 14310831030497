import React, { useEffect } from 'react';

function WindButtons(props) {
  const [wind, setWind] = React.useState({ id: 'wind-00', strength: 0 });
  const inactive = props.inactive;
  const existingData = props.data.wind !== '' ? true : false;

  // console.log(props.data.wind);

  const windOptions = [
    { id: 'wind-01', strength: 1, symbol: 'air' },
    { id: 'wind-02', strength: 2, symbol: 'air' },
    { id: 'wind-03', strength: 3, symbol: 'air' },
  ];

  useEffect(() => {
    if (!inactive) {
      const defaultWind = { id: 'wind-00', strength: 0 };
      const updatedWind = props.data.wind
        ? {
            id: props.data.wind,
            strength: Number(
              String(props.data.wind).charAt(props.data.wind.length - 1)
            ),
          }
        : defaultWind;

      setWind(updatedWind);
    }
  }, [props.data.wind, inactive]);

  let windButtons = windOptions.map((x, i) => (
    <button
      key={x.id}
      id={x.id}
      className={
        existingData
          ? i <
            Number(String(props.data.wind).charAt(props.data.wind.length - 1))
            ? 'increase-chosen'
            : ''
          : i < wind.strength
          ? 'increase-chosen'
          : ''
      }
      onClick={inactive === false ? () => guessWind(x.id) : undefined}
    >
      <span className='material-symbols-outlined'>{x.symbol}</span>
    </button>
  ));

  const guessWind = id => {
    let newWind;
    if (id === 'wind-01' && wind.strength === 1) {
      newWind = { id: 'wind-00', strength: 0 };
    } else {
      let idString = String(id);
      let strength = Number(idString.charAt(idString.length - 1));
      newWind = { id: id, strength: strength };
    }
    // console.log('guessWind', newWind);
    setWind(newWind);
  };

  useEffect(() => {
    if (!inactive) {
      let newData = { ...props.data };
      newData.wind = wind.id;
      props.setData(newData);
    }
  }, [wind]);

  return (
    <div className='windButtons guessing-buttons increase-buttons'>
      {windButtons}
    </div>
  );
}

export default WindButtons;
