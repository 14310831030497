function Intro() {
  return (
    <section className='intro'>
      <h2>Take your business to the next level</h2>
      <p>
        Do you just want a webpage to present your business, would you like your
        customers to find you more easily online, or are you planning a
        full-blown international marketing campaign? Sounds great - I will help
        you make it happen!
      </p>

      <p>
        With close to a decade of experience in digital marketing and websites,
        and a personal passion for writing, I would love to help you reach your
        local and international business goals, no matter how big or small they
        are.
      </p>

      <p>
        And if you want to have a chat about how to be successful specifically
        in the German Market, please reach out. I'm always happy to share my
        knowledge of my home country (and maybe even my recipe for the world's
        best käsespätzle).
      </p>
    </section>
  );
}

export default Intro;
