import Hero from '../../app_components/Hero';
import ContentCard from '../../app_components/ContentCard';
import ContentCardContainer from '../../app_components/ContentCardContainer';

import Intro from './Intro';

function Consulting() {
  return (
    <>
      <Hero
        h1='Nadja Koskela Consulting'
        p='Digital marketing expertise with a German twist'
        contact='true'
        ctaText='Get in touch'
      />
      <main>
        <Intro />
        {/* <ContentCardContainer
          padding='20%'
          mobilePadding='5%'
          columns='3'
          content={
            <>
              <ContentCard
                symbol='add_business'
                copy='Digital Marketing'
                link='/consulting'
              />
              <ContentCard symbol='web' copy='Web Dev' link='/consulting' />
              <ContentCard
                symbol='history_edu'
                copy='Writing & more'
                link='/consulting'
              />
            </>
          }
        /> */}
        <button
          className='button btn-subtle'
          onClick={() =>
            (window.location.href = 'mailto:koskelanadja@gmail.com')
          }
        >
          Let's talk
        </button>
      </main>
    </>
  );
}

export default Consulting;
